import styles from './index.module.scss'
// import ICON_LOGO from '@/assets/imgs/icon_logo.png'
// import ICON_SEARCH from '@/assets/imgs/icon_search.png'

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.title}>
            {/* <img src={ICON_LOGO} /> */}
            <p>爱智汇（深圳）教育发展有限公司</p>
          </div>
          {/* <div className={styles.search}>
            <div className={styles.inputBlock}>
              <input className={styles.input} placeholder="请输入搜索内容" />
              <div className={styles.suffix} data-button>
                <img className={styles.icon} src={ICON_SEARCH} />
              </div>
            </div>
            <div className={styles.hotSearch}>
              热门搜索：报考条件 报考流程 政策改革 就业前景 报告条件
            </div>
          </div> */}
        </div>

        <div className={styles.menu}>
          <a className={styles.menuItem} data-button>
            报考入门
          </a>
          <a className={styles.menuItem} data-button>
            报考咨询
          </a>
          <a className={styles.menuItem} data-button>
            考试科目
          </a>
          <a className={styles.menuItem} data-button>
            历年真题
          </a>
          <a className={styles.menuItem} data-button>
            政策解读
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header
