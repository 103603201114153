import Helmet from 'react-helmet'
import { Outlet } from 'react-router-dom'
import Header from './components/Header'
import styles from './index.module.scss'

export default () => {
  return (
    <>
      <Helmet>
        <title>爱智汇（深圳）教育发展有限公司</title>
        <meta name="description" content="爱智汇（深圳）教育发展有限公司" />
        <meta name="keywords" content="爱智汇（深圳）教育发展有限公司" />
      </Helmet>
      <div className={styles.main}>
        <Header />
        <Outlet />
        <div className={styles.footer}>
          <a href={'https://beian.miit.gov.cn'} target="_blank" rel="noreferrer">
            粤ICP备2023060420号
          </a>
          <p>Copyright 2023 版权所有</p>
        </div>
      </div>
    </>
  )
}
